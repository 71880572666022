import React, { useState, useContext } from 'react';
import './ProgramAndServices.css';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const content = {
  English: {
    pageTitle: 'Programs & Services',
    items: [
      {
        title: 'Academic Intervention and Support',
        description: 'Excel in your academic journey with our Academic Intervention and Support program. Tailored for students seeking additional assistance and resources, this initiative is designed to provide personalized support for academic success. Overview: Navigate your academic path with confidence through our Academic Intervention and Support program. We understand that each student is unique, and our program is crafted to address individual needs, fostering a positive and empowering learning environment.',
      },
      {
        title: 'Expanded Opportunities and Athletics',
        description: 'Experience a holistic approach to personal and professional development through our Expanded Opportunities and Athletics program. Tailored for individuals seeking a well-rounded journey, this initiative offers a dynamic blend of expanded opportunities and athletic engagement...',
      },
      {
        title: 'Family Support',
        description: 'Empower your family with the resources and assistance they need through our Family Support program. Tailored for families navigating challenges or seeking additional support, this initiative is designed to strengthen familial bonds and enhance overall well-being...',
      },
      {
        title: 'Affordable Housing & Shelter',
        description: 'At Community Impact Hub, we believe that everyone deserves a safe and affordable place to call home. Our Affordable Housing and Shelter program is dedicated to providing accessible housing solutions for individuals and families in need.',
      },
    ],
  },
  French: {
    pageTitle: 'Programmes et Services',
    items: [
      {
        title: 'Intervention et Soutien Académique',
        description: 'Excellez dans votre parcours académique avec notre programme d\'Intervention et de Soutien Académique. Conçu pour les étudiants recherchant une assistance et des ressources supplémentaires, cette initiative est destinée à fournir un soutien personnalisé pour le succès académique. Aperçu : Parcourez votre chemin académique avec confiance grâce à notre programme d\'Intervention et de Soutien Académique. Nous comprenons que chaque étudiant est unique, et notre programme est conçu pour répondre aux besoins individuels, favorisant un environnement d\'apprentissage positif et stimulant.',
      },
      {
        title: 'Opportunités Élargies et Athlétisme',
        description: 'Découvrez une approche holistique du développement personnel et professionnel grâce à notre programme Opportunités Élargies et Athlétisme. Conçu pour les personnes recherchant un parcours complet, cette initiative offre un mélange dynamique d\'opportunités élargies et d\'engagement athlétique...',
      },
      {
        title: 'Soutien Familial',
        description: 'Renforcez votre famille avec les ressources et l\'assistance dont elle a besoin grâce à notre programme de Soutien Familial. Conçu pour les familles faisant face à des défis ou recherchant un soutien supplémentaire, cette initiative est destinée à renforcer les liens familiaux et à améliorer le bien-être général...',
      },
      {
        title: 'Logement Abordable et Hébergement',
        description: 'Chez Community Impact Hub, nous croyons que chacun mérite un endroit sûr et abordable où se sentir chez soi. Notre programme de Logement Abordable et d\'Hébergement est dédié à fournir des solutions de logement accessibles pour les individus et les familles dans le besoin.',
      },
    ],
  },
};

const ProgramAndServices = () => {
  const [selectedCard, setSelectedCard] = useState(null);
  const { selectedLanguage } = useContext(LanguageContext);

  const { pageTitle, items } = content[selectedLanguage] || content.English;

  const handleCardClick = (index) => {
    setSelectedCard(index === selectedCard ? null : index);
  };

  return (
    <div className='outer-program'>
      <div className='content'>
        <h1>{pageTitle}</h1>
      </div>
      <div className="card-container">
        {items.map((item, index) => (
          <div
            key={index}
            className={`card ${selectedCard === index ? 'selected' : ''}`}
            onClick={() => handleCardClick(index)}
          >
            <h3>{item.title}</h3>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgramAndServices;
