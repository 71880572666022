import React, { useContext, useState } from 'react';
import './SummerCamp.css';
import Form from '../../components/form/Form';
import SignInForm from '../../components/form/SignInForm';
import { useNavigate } from 'react-router-dom';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';
import { UserContext } from '../../contexts/UserContext/UserContext';

const content = {
  English: {
    title: 'High Performance Conditioning Camp',
    subtitle: '(Ages 13-16)',
    techLabTitle: 'Tech Lab',
    techLabSubtitle: 'Explore the latest in technology',
    registrationDate: 'Registration for our summer program opens on Monday Jan 16th 2024',
    registerButton: 'REGISTER HERE: Summer Camp Program',
    datesTitle: 'Dates & Times',
    dates: [
      'Session 1: July 8 - July 12',
      'Session 2: July 15 - July 19',
      'Session 3: July 22 - July 26',
      'Session 4: July 29 - Aug 2',
      'Session 5: Aug 6 - Aug 9 (4 days)',
      'Session 6: Aug 12 - Aug 16',
    ],
    registrationTitle: 'UP PERFORMANCE Coding and Basketball CAMPS STILL HAVE ROOM BUT TIME IS RUNNING OUT!',
    registrationDetails: [
      'A Camp for young Athlete looking to better their Basketball skills, Coding and Digital skills and become great and have fun doing it!',
      '08:30 AM TO 04:30 PM',
      'Register for the Up Performance Basketball and Coding Camp where your young athlete will participate in our daily Performance Programs and a variety of Camp activities. These camps will be led by trained coaches from UP Performance under the Supervision of our skills coach.',
      'Participants will engage in challenging and fun exercises. We will show the athlete how to improve their ball handling, how to shoot like an elite player, how to finish as a pro, and how to defend against the best. We will teach the player the winner mindset. Athletes will work to get top-notch strength and conditioning.',
    ],
    learningTitle: 'Fun and Flexible Learning',
    learningDetails: 'Kids learn together in live, hands-on, interactive programs run by Engineering student from university who will act as live mentors. Kids can design, create, play, and code together in a small group setting, leading to fun and friendships.',
    morningLearnTitle: 'What Campers Learn in the morning',
    morningLearnDetails: [
      'Coding, design, and media production skills.',
      'Problem-solving.',
      'Teamwork and collaboration.',
      'Planning.',
      'The value of community.',
    ],
    bringTitle: 'What to Bring',
    bringItems: [
      'Laptop',
      'Flash drive for saving files',
      'Adobe ID (sign up for free Adobe ID at adobe.com and bring the user name and password with you)',
      'Google Drive account (or similar cloud account) already set up in order to upload your files to the cloud',
    ],
    registerNowTitle: 'Register Now',
    registerNowButton: 'Spring / Summer Registration',
  },
  French: {
    title: 'Camp de Conditionnement Haute Performance',
    subtitle: '(Ages 13-16)',
    techLabTitle: 'Tech Lab',
    techLabSubtitle: 'Découvrez les dernières technologies',
    registrationDate: 'Les inscriptions pour notre programme d\'été ouvrent le lundi 16 janvier 2024',
    registerButton: 'INSCRIVEZ-VOUS ICI : Programme de Camp d\'été',
    datesTitle: 'Dates & Horaires',
    dates: [
      'Session 1 : 8 juillet - 12 juillet',
      'Session 2 : 15 juillet - 19 juillet',
      'Session 3 : 22 juillet - 26 juillet',
      'Session 4 : 29 juillet - 2 août',
      'Session 5 : 6 août - 9 août (4 jours)',
      'Session 6 : 12 août - 16 août',
    ],
    registrationTitle: 'LES CAMPS DE CODAGE ET DE BASKETBALL UP PERFORMANCE ONT ENCORE DE LA PLACE MAIS LE TEMPS EST COMPTE !',
    registrationDetails: [
      'Un camp pour les jeunes athlètes cherchant à améliorer leurs compétences en basketball, en codage et en compétences numériques, tout en s’amusant !',
      '08:30 AM À 04:30 PM',
      'Inscrivez-vous au Camp de Basketball et de Codage Up Performance où votre jeune athlète participera à nos programmes quotidiens de performance et à diverses activités de camp. Ces camps seront dirigés par des entraîneurs formés de UP Performance sous la supervision de notre entraîneur de compétences.',
      'Les participants s’engageront dans des exercices stimulants et amusants. Nous montrerons à l’athlète comment améliorer sa gestion du ballon, comment tirer comme un joueur d’élite, comment terminer comme un pro et comment défendre contre les meilleurs. Nous enseignerons à l’athlète l’état d’esprit de gagnant. Les athlètes travailleront à obtenir une force et une condition physique de premier ordre.',
    ],
    learningTitle: 'Apprentissage Amusant et Flexible',
    learningDetails: 'Les enfants apprennent ensemble dans des programmes interactifs pratiques animés par des étudiants en ingénierie de l’université qui agiront en tant que mentors en direct. Les enfants peuvent concevoir, créer, jouer et coder ensemble dans un cadre de petit groupe, ce qui conduit à du plaisir et à des amitiés.',
    morningLearnTitle: 'Ce que les campeurs apprennent le matin',
    morningLearnDetails: [
      'Compétences en codage, design et production médiatique.',
      'Résolution de problèmes.',
      'Travail d’équipe et collaboration.',
      'Planification.',
      'La valeur de la communauté.',
    ],
    bringTitle: 'Ce qu\'il faut apporter',
    bringItems: [
      'Ordinateur portable',
      'Clé USB pour sauvegarder les fichiers',
      'ID Adobe (inscrivez-vous gratuitement à Adobe ID sur adobe.com et apportez le nom d’utilisateur et le mot de passe avec vous)',
      'Compte Google Drive (ou compte cloud similaire) déjà configuré pour télécharger vos fichiers sur le cloud',
    ],
    registerNowTitle: 'Inscrivez-vous maintenant',
    registerNowButton: 'Inscriptions Printemps / Été',
  },
};

const SummerCamp = () => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);
  const navigate = useNavigate();
  const { selectedLanguage } = useContext(LanguageContext);
  const { user } = useContext(UserContext);

  const { 
    title,
    subtitle,
    techLabTitle,
    techLabSubtitle,
    registrationDate,
    registerButton,
    datesTitle,
    dates,
    registrationTitle,
    registrationDetails,
    learningTitle,
    learningDetails,
    morningLearnTitle,
    morningLearnDetails,
    bringTitle,
    bringItems,
    registerNowTitle,
    registerNowButton
  } = content[selectedLanguage] || content.English;

  const openRegisterModal = () => {
    setShowRegisterModal(true);
  };

  const closeRegisterModal = () => {
    setShowRegisterModal(false);
  };

  const openSignInModal = () => {
    setShowSignInModal(true);
  };

  const closeSignInModal = () => {
    setShowSignInModal(false);
  };

  const handleRegisterClick = () => {
    if (user) {
      // User is logged in, redirect to child registration page
      navigate('/child-registration');
    } else {
      // User is not logged in, open sign in modal
      openSignInModal();
    }
  };

  return (
    <div className='summer-camp'>
      <div className='banner' style={{ backgroundImage: `url(${require('../../images/ballgym.jpeg')})` }}>
        <h1>{title}</h1>
        <h2>{subtitle}</h2>
      </div>
      <div className='banner' style={{ backgroundImage: `url(${require('../../images/techlab.jpg')})` }}>
        <h1>{techLabTitle}</h1>
        <h2>{techLabSubtitle}</h2>
      </div>
      <div className='content'>
        <h2 style={{ color: "#5a8375" }}>{registrationDate}</h2>
        <button className='register-button' onClick={handleRegisterClick}>{registerButton}</button>
        <div className='session'>
          <h2>{datesTitle}</h2>
          <div className='session-list'>
            {dates.map((date, index) => (
              <div key={index}>{date}</div>
            ))}
          </div>
        </div>
        <div className='registration'>
          <h2>{registrationTitle}</h2>
          {registrationDetails.map((detail, index) => (
            <p key={index}>{detail}</p>
          ))}
        </div>
        <div className='learning'>
          <h2>{learningTitle}</h2>
          <p>{learningDetails}</p>
        </div>
        <div className='morning-learn'>
          <h2>{morningLearnTitle}</h2>
          <p>Our programs turn “screen time” into learning time - while still preserving all the fun! Your child will learn valuable skills for today's digital world in our camps and Labs.</p>
          <div className='morning-learn-list'>
            {morningLearnDetails.map((detail, index) => (
              <div key={index}>{detail}</div>
            ))}
          </div>
        </div>
        <div className='bring'>
          <h2>{bringTitle}</h2>
          <div className='bring-list'>
            {bringItems.map((item, index) => (
              <div key={index}>{item}</div>
            ))}
          </div>
        </div>
        <div className='register-section'>
          <h2>{registerNowTitle}</h2>
          <button onClick={handleRegisterClick}>{registerNowButton}</button>
        </div>
      </div>
      {showRegisterModal && <Form onClose={closeRegisterModal} onBack={openSignInModal} />}
      {showSignInModal && <SignInForm onClose={closeSignInModal} onSignUp={openRegisterModal} />}
    </div>
  );
}

export default SummerCamp;
