import React, { useContext } from 'react';
import './Footer.css';
import { Link } from 'react-router-dom'; 
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt, FaFacebook, FaTwitter } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const Footer = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const englishContent = (
    <div className="footer">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="company-info">
          <h1>Community Impact Hub</h1>
          <p>We are dedicated to empowering communities, fostering inclusivity, and contributing to the overall well-being of society. 
            Through collaborative efforts and a commitment to transparency, we aim to create tangible and lasting impact, inspiring 
            individuals to actively engage in building a better, more equitable future.</p>
          <div className='socials-icons'>
            <FaFacebook size={28} color={'black'} style={{ marginRight: '45px' }} />
            <FaTwitter size={28} color={'black'} style={{ marginRight: '45px' }} />
            <RiInstagramFill size={28} color={'black'} style={{ marginRight: '20px' }} />
          </div>
        </div>

        <div className="links-and-contact">
          <div className="links-column">
            <h5>Navigation</h5>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/resources">Resources</Link></li>
            <li><Link to="/programs">Programs & Services</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/summercamp">Summer Camp Program</Link></li>
          </div>
          <div className="links-column">
            <h5>About Us</h5>
            <li><Link to="/about">Mission</Link></li>
            <li><Link to="/about">Vision</Link></li>
            <li><Link to="/about">Our Values</Link></li>
            <li><Link to="/about">Diversity, Equity & Inclusion</Link></li>
            <li><Link to="/about">Our Team</Link></li>
          </div>
          <div className="links-column">
            <h5>Programs & Services</h5>
            <li><Link to="/programs">Overview</Link></li>
            <li><Link to="/programs">Learn More</Link></li>
            <li><Link to="/programs">Schedule</Link></li>
            <li><Link to="/programs">Pricing</Link></li>
          </div>
          <div className="links-column">
            <h5>Resources</h5>
            <li><Link to="/resources">Resources</Link></li>
            <li><Link to="/resources">Publications</Link></li>
            <li><Link to="/resources">Podcast</Link></li>
            <li><Link to="/resources">News</Link></li>
            <li><Link to="/resources">Events</Link></li>
          </div>
          <div className="links-column">
            <h5>Contact</h5>
            <li><Link to="/contact">Contact Form</Link></li>
            <p><MdEmail size={"16"} color={"white"}/> info@communityimpacthub.com</p>
            <p><FaPhoneAlt color={"white"}/> 613-406-2254</p>
          </div>
        </div>
      </div>
      <p>&copy; 2024 communityimpacthub.ca</p>
    </div>
  );

  const frenchContent = (
    <div className="footer">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div className="company-info">
          <h1>Community Impact Hub</h1>
          <p>Nous sommes dédiés à l'autonomisation des communautés, à la promotion de l'inclusivité et à la contribution au bien-être général de la société. 
            Par des efforts collaboratifs et un engagement envers la transparence, nous visons à créer un impact tangible et durable, en inspirant 
            les individus à s'engager activement dans la construction d'un avenir meilleur et plus équitable.</p>
          <div className='socials-icons'>
            <FaFacebook size={28} color={'black'} style={{ marginRight: '45px' }} />
            <FaTwitter size={28} color={'black'} style={{ marginRight: '45px' }} />
            <RiInstagramFill size={28} color={'black'} style={{ marginRight: '20px' }} />
          </div>
        </div>

        <div className="links-and-contact">
          <div className="links-column">
            <h5>Navigation</h5>
            <li><Link to="/">Accueil</Link></li>
            <li><Link to="/resources">Ressources</Link></li>
            <li><Link to="/programs">Programmes et services</Link></li>
            <li><Link to="/contact">Nous contacter</Link></li>
            <li><Link to="/summercamp">Programme de camp d'été</Link></li>
          </div>
          <div className="links-column">
            <h5>À propos de nous</h5>
            <li><Link to="/about">Mission</Link></li>
            <li><Link to="/about">Vision</Link></li>
            <li><Link to="/about">Nos valeurs</Link></li>
            <li><Link to="/about">Diversité, équité et inclusion</Link></li>
            <li><Link to="/about">Notre équipe</Link></li>
          </div>
          <div className="links-column">
            <h5>Programmes et services</h5>
            <li><Link to="/programs">Aperçu</Link></li>
            <li><Link to="/programs">En savoir plus</Link></li>
            <li><Link to="/programs">Horaire</Link></li>
            <li><Link to="/programs">Tarification</Link></li>
          </div>
          <div className="links-column">
            <h5>Ressources</h5>
            <li><Link to="/resources">Ressources</Link></li>
            <li><Link to="/resources">Publications</Link></li>
            <li><Link to="/resources">Podcast</Link></li>
            <li><Link to="/resources">Actualités</Link></li>
            <li><Link to="/resources">Événements</Link></li>
          </div>
          <div className="links-column">
            <h5>Contact</h5>
            <li><Link to="/contact">Formulaire de contact</Link></li>
            <p><MdEmail size={"16"} color={"white"}/> info@communityimpacthub.com</p>
            <p><FaPhoneAlt color={"white"}/> 613-406-2254</p>
          </div>
        </div>
      </div>
      <p>&copy; 2024 communityimpacthub.ca</p>
    </div>
  );

  return selectedLanguage === "French" ? frenchContent : englishContent;
};

export default Footer;
