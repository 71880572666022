import React, { useState, useContext } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Form.css';
import { IoMdArrowBack } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import {
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { signUp } from '../../firebase/firebaseAuth';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(/^[a-zA-Z]*$/, 'First Name should only contain letters')
    .required('First Name is required'),
  lastName: Yup.string()
    .matches(/^[a-zA-Z]*$/, 'Last Name should only contain letters')
    .required('Last Name is required'),
  birthday: Yup.date()
    .max(new Date(), "Birthday should be before today's date")
    .required('Birthday is required'),
  phoneNumber: Yup.string()
    .matches(/^[0-9]{3}-?[0-9]{3}-?[0-9]{4}$/, 'Phone Number should be in format 123-456-7890 or 1234567890')
    .required('Phone Number is required'),
  email: Yup.string()
    .email('Invalid email')
    .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Invalid email format')
    .required('Email is required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is required'),
});

const Form = ({ onClose, onBack }) => {
  const [signUpError, setSignUpError] = useState(null);
  const { selectedLanguage } = useContext(LanguageContext);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      birthday: '',
      phoneNumber: '',
      email: '',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await signUp(values.email, values.password);
        onClose();
        onBack();
      } catch (error) {
        console.error('Registration Error:', error.message);
        setSignUpError(selectedLanguage === "English" ? 'Registration Error. Please try again.' : 'Erreur d\'inscription. Veuillez réessayer.');
      }
    },
  });

  const handleBackClick = () => {
    onClose();
    onBack();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <div className='icon-container'>
        <IoMdArrowBack className="icon" size={24} onClick={handleBackClick} />
        <IoClose className='icon close' size={24} onClick={handleClose} />
      </div>
      <DialogTitle style={{ marginBottom: '10px' }}>
        {selectedLanguage === "English" ? "Register Form" : "Formulaire d'inscription"}
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} className="form">
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label={selectedLanguage === "English" ? "First Name" : "Prénom"}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
            variant="outlined"
            size="large"
            className="form-field"
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label={selectedLanguage === "English" ? "Last Name" : "Nom de famille"}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
            variant="outlined"
            size="large"
            className="form-field"
          />
          <TextField
            fullWidth
            id="birthday"
            name="birthday"
            label={selectedLanguage === "English" ? "Birthday" : "Date de naissance"}
            type="date"
            value={formik.values.birthday}
            onChange={formik.handleChange}
            error={formik.touched.birthday && Boolean(formik.errors.birthday)}
            helperText={formik.touched.birthday && formik.errors.birthday}
            variant="outlined"
            size="large"
            className="form-field"
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            fullWidth
            id="phoneNumber"
            name="phoneNumber"
            label={selectedLanguage === "English" ? "Phone Number" : "Numéro de téléphone"}
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            variant="outlined"
            size="large"
            className="form-field"
          />
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={(formik.touched.email && formik.errors.email) || (!formik.errors.email && (selectedLanguage === "English" ? "Enter a valid email address" : "Entrez une adresse email valide"))}
            variant="outlined"
            size="large"
            className="form-field"
          />
          <TextField
            fullWidth
            id="password"
            name="password"
            label={selectedLanguage === "English" ? "Password" : "Mot de passe"}
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password ? formik.errors.password : (selectedLanguage === "English" ? "Password must be at least 6 characters" : "Le mot de passe doit comporter au moins 6 caractères")}
            variant="outlined"
            size="large"
            className="form-field"
          />
          <DialogActions className="actions">
            <Button style={{ backgroundColor: "#5a8375" }} type="submit" variant="contained">
              {selectedLanguage === "English" ? "Submit" : "Soumettre"}
            </Button>
            <Button onClick={handleClose} color="primary">
              {selectedLanguage === "English" ? "Cancel" : "Annuler"}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default Form;
