// About.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import './About.css';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const About = () => {
  const navigate = useNavigate();
  const { selectedLanguage } = useContext(LanguageContext);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <div className='outer-container'>
      <div className="about-container">
        {selectedLanguage === "English" ? (
          <>
            <h2 className="about-header">About Us</h2>
            <p className="about-paragraph">
              Founded in Ontario, Community Impact Hub is a non-profit organization geared towards making a positive impact 
              in our local communities and beyond. Committed to addressing pressing social and occupational challenges, 
              we strive to create lasting change through innovative initiatives and collaborative efforts. Our team is comprised of 
              passionate individuals who share a common vision of building a more inclusive, sustainable, and compassionate business 
              environment. Drawing inspiration from the rich diversity of Ontario, we work tirelessly to implement programs that 
              empower individuals, foster community engagement, and contribute to the greater well-being of our communities. 
              With a focus on transparency, accountability, and integrity, our organization stands as a beacon of hope and progress 
              driven by the belief that collective action can truly make a difference. Join us on this meaningful journey as we work 
              together to create a brighter future for all.
            </p>
            <div className="columns">
              <div className="column">
                <h2>Mission</h2>
                <p>Our mission is to catalyze positive change in Ontario and Canada-wide, by addressing pressing social and environmental 
                  issues through innovative and sustainable initiatives. We are dedicated to empowering communities, fostering inclusivity, 
                  and contributing to the overall well-being of society. Through collaborative efforts and a commitment to transparency, 
                  we aim to create tangible and lasting impact, inspiring individuals to actively engage in building a better, 
                  more equitable future.</p>
                <h2>Diversity, Equity and Inclusion</h2>
                <p>Our commitment to Diversity, Equity, and Inclusion (DEI) is woven into the fabric of our organization. 
                  We recognize that embracing diversity in all its forms is not only a strength but a fundamental necessity for 
                  creating a truly equitable and just society. We are dedicated to fostering an inclusive environment where 
                  individuals from diverse backgrounds, experiences, and perspectives feel valued, heard, and empowered. 
                  In our pursuit of equity, we actively work to dismantle systemic barriers and promote fair opportunities for all. 
                  By cultivating a culture that celebrates differences, we believe we can harness the collective power of our varied 
                  perspectives to drive innovation, resilience, and positive change. Our commitment to DEI is not just a value; 
                  it is a guiding principle that shapes our actions, policies, and programs, ensuring that we contribute to a 
                  more equitable and harmonious community for everyone.</p>
              </div>
              <div className="column">
                <h2>Vision</h2>
                <p>Our vision is a thriving entrepreneurial community in Ontario and Canada where every individual has the opportunity 
                  to reach their full potential and where communities are resilient, inclusive and environmentally sustainable. 
                  We aspire to be a catalyst for transformative change, leveraging the power of collective action to create a society 
                  that values diversity, promotes social justice, and embraces environmental stewardship. By fostering collaboration and 
                  implementing forward-thinking solutions, we envision a future where our efforts serve as a model for positive change, 
                  inspiring others to join us in creating a more compassionate and sustainable world.</p>
                <h2>Our Values</h2>
                <p>At the core of our organization lie a set of guiding values that shape our identity and steer our actions. 
                  Integrity is the cornerstone of all our endeavors, as we uphold the highest ethical standards in every interaction 
                  and decision. We are committed to fostering inclusivity, recognizing the strength that diversity brings to our 
                  communities. Collaboration is ingrained in our DNA, as we believe in the power of working together to achieve 
                  impactful results. Transparency is paramount, ensuring that our stakeholders are informed and engaged in our mission. 
                  We embrace innovation, continuously seeking creative solutions to address complex challenges. Compassion is the 
                  driving force behind our work, motivating us to make a positive difference in the lives of those we serve. 
                  Through these values, we strive to build a resilient, just, and sustainable future for Ontario, Canada and beyond.</p>
              </div>
            </div>

            <div className="additional-content">
              <h2>About Us</h2>
              <p>Our organization is fueled by a dedicated and passionate team of individuals who share a common commitment to our 
                mission and values. Comprising a diverse range of talents, experiences, and expertise, our team collaborates seamlessly 
                to bring about positive change in Ontario. From community organizers to environmental advocates, each member plays a 
                crucial role in advancing our initiatives. Grounded in transparency and open communication, our team fosters an inclusive 
                and supportive work environment where creativity and innovation flourish. As a collective force, we strive to address 
                complex challenges with resilience, dedication, and a shared vision for a better future. Together, we embody the spirit 
                of collaboration, embodying the belief that by working united, we can achieve meaningful impact and create lasting 
                positive change in the communities we serve.</p>
                <button className="big-button" onClick={handleGoBack}>Back</button>
            </div>
          </>
        ) : (
          <>
            <h2 className="about-header">À Propos de Nous</h2>
            <p className="about-paragraph">
              Fondé en Ontario, Community Impact Hub est une organisation à but non lucratif visant à avoir un impact positif 
              dans nos communautés locales et au-delà. Engagés à relever les défis sociaux et professionnels pressants, 
              nous nous efforçons de créer un changement durable par le biais d'initiatives innovantes et d'efforts collaboratifs. 
              Notre équipe est composée de personnes passionnées partageant une vision commune de construction d'un environnement 
              commercial plus inclusif, durable et compatissant. Tirant inspiration de la riche diversité de l'Ontario, nous travaillons 
              sans relâche pour mettre en œuvre des programmes qui autonomisent les individus, favorisent l'engagement communautaire et 
              contribuent au bien-être général de nos communautés. Avec un accent sur la transparence, la responsabilité et l'intégrité, 
              notre organisation se veut un phare d'espoir et de progrès, portée par la conviction que l'action collective peut vraiment 
              faire une différence. Rejoignez-nous dans ce voyage significatif alors que nous travaillons ensemble pour créer un avenir 
              plus lumineux pour tous.
            </p>
            <div className="columns">
              <div className="column">
                <h2>Mission</h2>
                <p>Notre mission est de catalyser le changement positif en Ontario et à travers le Canada en abordant les enjeux sociaux et 
                  environnementaux pressants par des initiatives innovantes et durables. Nous nous engageons à autonomiser les communautés, 
                  à favoriser l'inclusivité et à contribuer au bien-être général de la société. Par le biais d'efforts collaboratifs et d'un 
                  engagement à la transparence, nous visons à créer un impact tangible et durable, en inspirant les individus à s'engager 
                  activement dans la construction d'un avenir meilleur et plus équitable.</p>
                <h2>Diversité, Équité et Inclusion</h2>
                <p>Notre engagement en faveur de la Diversité, de l'Équité et de l'Inclusion (DEI) est ancré dans le tissu de notre organisation. 
                  Nous reconnaissons que l'acceptation de la diversité sous toutes ses formes n'est pas seulement une force, mais une nécessité 
                  fondamentale pour créer une société véritablement équitable et juste. Nous nous engageons à favoriser un environnement inclusif 
                  où les individus de divers horizons, expériences et perspectives se sentent valorisés, entendus et autonomisés. Dans notre quête 
                  de l'équité, nous travaillons activement à démanteler les barrières systémiques et à promouvoir des opportunités équitables pour tous. 
                  En cultivant une culture qui célèbre les différences, nous croyons que nous pouvons exploiter la puissance collective de nos 
                  perspectives variées pour stimuler l'innovation, la résilience et le changement positif. Notre engagement envers le DEI n'est pas 
                  seulement une valeur ; c'est un principe directeur qui façonne nos actions, politiques et programmes, garantissant que nous contribuons 
                  à une communauté plus équitable et harmonieuse pour tous.</p>
              </div>
              <div className="column">
                <h2>Vision</h2>
                <p>Notre vision est une communauté entrepreneuriale florissante en Ontario et au Canada où chaque individu a l'opportunité 
                  d'atteindre son plein potentiel et où les communautés sont résilientes, inclusives et durables sur le plan environnemental. 
                  Nous aspirons à être un catalyseur de changement transformationnel, en exploitant le pouvoir de l'action collective pour créer 
                  une société qui valorise la diversité, promeut la justice sociale et adopte la gestion environnementale. En favorisant la collaboration 
                  et en mettant en œuvre des solutions avant-gardistes, nous envisageons un avenir où nos efforts servent de modèle pour le changement 
                  positif, inspirant d'autres à nous rejoindre dans la création d'un monde plus compatissant et durable.</p>
                <h2>Nos Valeurs</h2>
                <p>Au cœur de notre organisation se trouvent un ensemble de valeurs directrices qui façonnent notre identité et orientent nos actions. 
                  L'intégrité est la pierre angulaire de toutes nos entreprises, alors que nous respectons les normes éthiques les plus élevées dans 
                  chaque interaction et décision. Nous nous engageons à favoriser l'inclusivité, reconnaissant la force que la diversité apporte à nos 
                  communautés. La collaboration est ancrée dans notre ADN, car nous croyons en la puissance du travail en commun pour obtenir des 
                  résultats significatifs. La transparence est primordiale, garantissant que nos parties prenantes sont informées et impliquées dans notre 
                  mission. Nous embrassons l'innovation, cherchant continuellement des solutions créatives pour relever des défis complexes. La compassion 
                  est la force motrice derrière notre travail, nous motivant à faire une différence positive dans la vie de ceux que nous servons. Par 
                  ces valeurs, nous nous efforçons de construire un avenir résilient, juste et durable pour l'Ontario, le Canada et au-delà.</p>
              </div>
            </div>

            <div className="additional-content">
              <h2>À Propos de Nous</h2>
              <p>Notre organisation est alimentée par une équipe dévouée et passionnée d'individus partageant un engagement commun envers notre 
                mission et nos valeurs. Composée d'une gamme diversifiée de talents, d'expériences et d'expertises, notre équipe collabore 
                harmonieusement pour apporter un changement positif en Ontario. Des organisateurs communautaires aux défenseurs de l'environnement, 
                chaque membre joue un rôle crucial dans l'avancement de nos initiatives. Ancrée dans la transparence et la communication ouverte, 
                notre équipe favorise un environnement de travail inclusif et favorable où la créativité et l'innovation prospèrent. En tant que 
                force collective, nous nous efforçons de relever des défis complexes avec résilience, dévouement et une vision partagée d'un avenir 
                meilleur. Ensemble, nous incarnons l'esprit de la collaboration, incarnant la conviction que, en travaillant unis, nous pouvons obtenir 
                un impact significatif et créer un changement positif durable dans les communautés que nous servons.</p>
                <button className="big-button" onClick={handleGoBack}>Retour</button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default About;
