import React, { useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import './Contact.css';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

// Define validation schema using Yup
const validationSchema = Yup.object().shape({
  fullName: Yup.string().required('Full Name is required'),
  email: Yup.string().email('Invalid email format').required('Email is required'),
  phoneNumber: Yup.string().matches(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$|^[0-9]{10}$/, 'Invalid phone number format').required('Phone Number is required'),
  message: Yup.string().required('Message is required'),
});

// Initial form values
const initialValues = {
  fullName: '',
  email: '',
  phoneNumber: '',
  message: '',
};

// Form submission handler
const onSubmit = async (values, { resetForm }) => {
  try {
    const response = await fetch('https://communityimpacthub.onrender.com/api/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(values)
    });

    if (response.ok) {
      // Optionally reset form fields or show success message
      resetForm();
    } else {
      console.error('Failed to send email');
      // Handle error, show error message, etc.
    }
  } catch (error) {
    console.error('Error sending email:', error);
  }
};

const Contact = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  // Hardcoded content for English and French
  const content = {
    English: {
      heading: 'Contact Us',
      fullNamePlaceholder: 'Full Name',
      emailPlaceholder: 'Email Address',
      phoneNumberPlaceholder: 'Phone Number',
      messagePlaceholder: 'Have anything to say?',
      sendButtonText: 'Send',
    },
    French: {
      heading: 'Contactez Nous',
      fullNamePlaceholder: 'Nom Complet',
      emailPlaceholder: 'Adresse E-mail',
      phoneNumberPlaceholder: 'Numéro de Téléphone',
      messagePlaceholder: 'Avez-vous quelque chose à dire ?',
      sendButtonText: 'Envoyer',
    }
  };

  const { heading, fullNamePlaceholder, emailPlaceholder, phoneNumberPlaceholder, messagePlaceholder, sendButtonText } = content[selectedLanguage] || content.English;

  return (
    <div className='outer-contact'>
      <div className="form-container">
        <h1>{heading}</h1>
        {/* Formik wrapper for the form */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched }) => (
            <Form>
              {/* Full Name field */}
              <Field type="text" name="fullName" placeholder={fullNamePlaceholder} className="input-field" />
              <ErrorMessage name="fullName" component="div" className="error-message" />

              {/* Email field */}
              <Field type="email" name="email" placeholder={emailPlaceholder} className="input-field" />
              <ErrorMessage name="email" component="div" className="error-message" />

              {/* Phone Number field */}
              <Field type="text" name="phoneNumber" placeholder={phoneNumberPlaceholder} className="input-field" />
              <ErrorMessage name="phoneNumber" component="div" className="error-message" />

              {/* Message textarea */}
              <Field as="textarea" name="message" placeholder={messagePlaceholder} className="message-field" />
              
              {/* Submit button */}
              <button type="submit" className="send-button">{sendButtonText}</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Contact;
