import React, { useContext } from 'react';
import './Success.css';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const content = {
  English: {
    title: 'Thank you for registering!',
    message: 'Thank you for your payment. An invoice has been sent to your email address.',
    instructions: 'Please read the info box below for e-transfer instructions if you have not paid already',
    importantMessage: 'IMPORTANT MESSAGE:',
    details: [
      'Contact the Office once registration is completed and you will be given the appropriate fees owed.',
      'Payment can then be made by e-transfer to <strong>upandproudcommunity@gmail.com</strong> (the e-transfer message MUST include name of child and the age group program registered in)',
      'Payment can also be paid by Cheque, or Cash which can be accepted by the registrar on the 1st day of practice',
      'FULL REFUNDS ARE GRANTED WITHIN 3 DAYS OF REGISTRATION. ALL REFUNDS BEYOND 3 DAYS WILL BE SUBJECT TO A $50 ADMIN FEE',
      'If you have any questions please contact:',
      'info@communityimpacthub.ca',
      '613-406-2254'
    ],
  },
  French: {
    title: 'Merci pour votre inscription !',
    message: 'Merci pour votre paiement. Une facture a été envoyée à votre adresse e-mail.',
    instructions: 'Veuillez lire la boîte d\'information ci-dessous pour les instructions de virement si vous n\'avez pas encore payé',
    importantMessage: 'MESSAGE IMPORTANT :',
    details: [
      'Contactez le bureau une fois l\'inscription terminée et vous recevrez les frais appropriés.',
      'Le paiement peut ensuite être effectué par virement à <strong>upandproudcommunity@gmail.com</strong> (le message du virement DOIT inclure le nom de l\'enfant et le programme de groupe d\'âge dans lequel il est inscrit)',
      'Le paiement peut également être effectué par chèque ou en espèces, acceptés par le registraire le premier jour de l\'entrainement',
      'LES REMBOURSEMENTS COMPLETS SONT ACCORDÉS DANS LES 3 JOURS SUIVANT L\'INSCRIPTION. TOUT REMBOURSEMENT AU-DELÀ DE 3 JOURS SERA SOUMIS À DES FRAIS ADMINISTRATIFS DE 50 $',
      'Si vous avez des questions, veuillez contacter :',
      'info@communityimpacthub.ca',
      '613-406-2254'
    ],
  },
};

const Success = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const {
    title,
    message,
    instructions,
    importantMessage,
    details
  } = content[selectedLanguage] || content.English;

  return (
    <div className='outer-container-success'>
      <div className='success-message'>
        <h1>{title}</h1>
        <p>{message}</p>
        <p>{instructions}</p>
        <div className='info-box-light-green'>
          <h2>{importantMessage}</h2>
          {details.map((detail, index) => (
            <p key={index} dangerouslySetInnerHTML={{ __html: detail }} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Success;
