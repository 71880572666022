import React, { useState, useEffect, useContext } from 'react';
import './ChildRegistration.css';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';
import strapiUrl from '../../utils/strapiapi';


const ChildRegistration = () => {
  const weeks = [
    'Week 1: July 8th - July 12th',
    'Week 2: July 15th - July 19st',
    'Week 3: July 22nd - July 26th',
    'Week 4: July 29th - August 2th',
    'Week 5: August 6th - August 9th',
    'Week 6: August 12th - August 16th',
  ];

  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [error, setError] = useState('');
  const [childRegistrationData, setChildRegistrationData] = useState(null);
  const [payment, setPayment] = useState('')
  const [isWaiver1Checked, setIsWaiver1Checked] = useState(false);
  const [isWaiver2Checked, setIsWaiver2Checked] = useState(false);
  const [isPaymentSelected, setIsPaymentSelected] = useState(false);
  const { selectedLanguage } = useContext(LanguageContext);

  const navigate = useNavigate();

  const handleWeekChange = (week) => {
    if (week === 'All Weeks') {
      // If "All Weeks" is selected, toggle it
      if (selectedWeeks.includes('All Weeks')) {
        setSelectedWeeks([]);
      } else {
        setSelectedWeeks(['All Weeks']);
      }
    } else {
      if (selectedWeeks.includes('All Weeks')) {
        setSelectedWeeks([week]);
      } else {
        if (selectedWeeks.includes(week)) {
          setSelectedWeeks(selectedWeeks.filter((selected) => selected !== week));
        } else {
          setSelectedWeeks([...selectedWeeks, week]);
        }
      }
    }

    setError('');
  };

  const validationSchema = Yup.object().shape({
    childName: Yup.string().required('Child\'s Name is required'),
    age: Yup.number().required('Age is required').positive('Age must be a positive number'),
    parentName: Yup.string().required('Parent\'s Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().required('Phone number is required'),
    emergencyContact: Yup.string().required('Emergency Contact is required'),
    emergencyContactNumber: Yup.string().required('Emergency Contact Number is required'),
    digitalSignature: Yup.string().required('Digital signature is required'),
    gender: Yup.string().required('Gender is required'),
  });

  const formik = useFormik({
    initialValues: {
      childName: '',
      age: '',
      parentName: '',
      email: '',
      phone: '',
      emergencyContact: '',
      emergencyContactNumber: '',
      gender: '',
      digitalSignature: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      if (selectedWeeks.length === 0) {
        setError('Please select at least one week');
        return;
      }
      
      // Prepare data to send to the server
      const data2 = {
        ...values,
        selectedWeeks: selectedWeeks, // Include selected weeks
      };

      // Send data to database
      /*
      fetch("https://communityimpacthub.onrender.com/api/add-data", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data2)
      })
      .then(res => {
        if (res.ok) return res.json();
        return res.json().then(json => Promise.reject(json));
      })
      .then(response => {
        // Handle success as needed, e.g., show success message
      })
      .catch(error => {
        console.error('Error sending data to server:', error);
        // Handle error as needed, e.g., show error message
      });
      */
      
      const paymentAmount = selectedWeeks.includes('All Weeks') ? '499' : (199 * selectedWeeks.length).toString();
      
      // Determine payment type
  const paymentType = payment === 'creditcard' ? 'credit card' : 'e-transfer';
  // Prepare data to send to the server
  const childData2 = {
    data: {
      childName: values.childName,
      age: values.age.toString(),
      gender: values.gender,
      parentName: values.parentName,
      email: values.email,
      phone: values.phone,
      emergencyContactName: values.emergencyContact,
      emergencyContactNumber: values.emergencyContactNumber,
      enrolledWeeks: selectedWeeks.map(week => ({
        type: "paragraph",
        children: [{ text: week, type: "text" }]
      })),
      payment_amount: paymentAmount,
      payment_type: paymentType,
    }
  };
/*
      fetch('https://strapi.digitalarts500.com/api/registered-children-infos', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(childData2),
    })
    .then(response => {
      if (response.ok) {
        // Data inserted successfully
      } else {
        // Error inserting data
        console.error('Error inserting child registration data 1:', response.statusText);
        // Handle error as needed
      }
    })
    .catch(error => {
      console.error('Error inserting child registration data:', error);
      // Handle error as needed
    });
    
    */
    
      
      // You can use the selectedWeeks array for further processing or submission
      let items = [];
      if (selectedWeeks.includes('All Weeks')) {
        items = [{ id: 7, quantity: 1 }];
      } else {
        items = selectedWeeks.map((week) => {
          const weekNumber = parseInt(week.split(':')[0].replace('Week ', ''), 10);
          return {
            id: weekNumber,
            quantity: 1,
            week: week,
          };
        });
      }
      if (payment == "creditcard"){
      const data = {
        items,
        email: values.email
      };
      fetch("https://communityimpacthub.onrender.com/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
      }).then(res => {
        if(res.ok) return res.json()
        return res.json().then(json => Promise.reject(json))
      }).then(({ url }) => {
        window.location = url
      }).catch(e => {
        console.error(e.error)
      })
      
      } else {
        const data = {
          items,
          email: values.email,
          grandTotal: paymentAmount
        };
        fetch("https://communityimpacthub.onrender.com/send-invoice", {
          method: "POST",
            headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(data)
        }).then(res => {
          if(res.ok) return res.json()
          return res.json().then(json => Promise.reject(json))
        }).then(() => {
          console.log("HELOHLEOHEOHELOHEL")
        }).catch(e => {
          console.error(e.error)
        })
        navigate("/payment-success")
      }
      
      // Reset selected weeks after submission (you can adjust this behavior as needed)
      formik.resetForm();
      setSelectedWeeks([]);
    },
  });
  
  const handleCheckbox1Change = (e) => {
    // Update first waiver checkbox status
    const checked = e.target.checked;
    setIsWaiver1Checked(checked);
  };

  const handleCheckbox2Change = (e) => {
    // Update second waiver checkbox status
    const checked = e.target.checked;
    setIsWaiver2Checked(checked);
  };

  const handlePaymentChange = (e) => {
    const selectedPayment = e.target.value;
    setPayment(selectedPayment);
    setIsPaymentSelected(true); // Set payment selection status to true when a payment option is selected
  };


  const englishContent = (
    <div className='outer-container-child'>
        <div className='container'>
          <div className='info'>
            <h1>Summer Camp Registration</h1>
            <p className='description'>
              Welcome to our exciting summer camp! Let your child explore the great outdoors,
              make new friends, and create unforgettable memories. Spaces are limited, so
              enroll your child today!
            </p>
            <div className='camp-details'>
              <p><strong>Age Range:</strong> 10 - 17 years old</p>
              <p><strong>Drop-off Time:</strong> 8:30 AM</p>
              <p><strong>Pickup Time:</strong> 4:30 PM</p>
              <p><strong>Camp Location:</strong> Sunny Valley Campground</p>
              <p><strong>Date Range:</strong> July 8th - August 16th</p>
            </div>
            <div className='pricing'>
              <h2>Pricing</h2>
              <p><strong>You can register your child for our summer camp, selecting any week</strong></p>
              <p>Regular Price: $199 per week</p>
              <p>Full Camp Bundle: $499 for 6 weeks</p>
            </div>
          </div>
            
          <div className="info-box">
              <p className="info-text">Payment of fees are due upon registration by credit card.
  
              Contact the Office once registration is completed and you will be given the appropriate fees owed.
              Payment can then be made by etransfer to <strong>upandproudcommunity@gmail.com</strong> (the etransfer message MUST include
              name of child and the age group program registered in)
  
              Payment can also be paid by Cheque, or Cash which can be accepted by the registrat on the 1st day of practice
  
              FULL REFUNDS ARE GRANTED WITHIN 3 DAYS OF REGISTRATION. ALL REFUNDS BEYOND 3 DAYS WILL BE SUBJECT TO A $50 ADMIN FEE</p>
            </div>
  
            <form className='registration-form' onSubmit={formik.handleSubmit}>
            <h2>Register Children</h2>
              <div className='form-group'>
                <label htmlFor='childName'>Child Name:</label>
                <input
                  type='text'
                  id='childName'
                  name='childName'
                  placeholder="Enter child's name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.childName}
                  className={formik.touched.childName && formik.errors.childName ? 'error' : ''}
                />
                {formik.touched.childName && formik.errors.childName ? (
                  <div className='error-message'>{formik.errors.childName}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='age'>Age:</label>
                <input
                  type='number'
                  id='age'
                  name='age'
                  placeholder="Enter child's age"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.age}
                  className={formik.touched.age && formik.errors.age ? 'error' : ''}
                />
                {formik.touched.age && formik.errors.age ? (
                  <div className='error-message'>{formik.errors.age}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label>Gender:</label>
                <div className='gender-section'>
                  <label>
                    Male
                    <input
                      type='radio'
                      name='gender'
                      value='male'
                      checked={formik.values.gender === 'male'}
                      onChange={formik.handleChange}
                    />
                  </label>
                  <label>
                    Female
                    <input
                      type='radio'
                      name='gender'
                      value='female'
                      checked={formik.values.gender === 'female'}
                      onChange={formik.handleChange}
                    />
                  </label>
                </div>
  
                {formik.touched.gender && formik.errors.gender ? (
                  <div className='error-message'>{formik.errors.gender}</div>
                ) : null}
              </div>
              
              <div className='form-group'>
                <label htmlFor='parentName'>Parent Name:</label>
                <input
                  type='text'
                  id='parentName'
                  name='parentName'
                  placeholder="Enter parent's name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.parentName}
                  className={formik.touched.parentName && formik.errors.parentName ? 'error' : ''}
                />
                
                {formik.touched.parentName && formik.errors.parentName ? (
                  <div className='error-message'>{formik.errors.parentName}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='email'>Email:</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  placeholder='Enter email'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={formik.touched.email && formik.errors.email ? 'error' : ''}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='error-message'>{formik.errors.email}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='phone'>Phone Number:</label>
                <input
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='Enter phone number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className='error-message'>{formik.errors.phone}</div>
                ) : null}
              </div>
  
              <label>Select Weeks:</label>
              <div className='week-checkboxes'>
                <div className='week-checkbox'>
                  <label htmlFor='allWeeks'>All Weeks</label>
                  <input
                    type='checkbox'
                    id='allWeeks'
                    name='allWeeks'
                    value='All Weeks'
                    checked={selectedWeeks.includes('All Weeks')}
                    onChange={() => handleWeekChange('All Weeks')}
                  />
                </div>
                {weeks.map((week, index) => (
                  <div key={index} className='week-checkbox'>
                    <label htmlFor={`week-${index}`}>{week}</label>
                    <input
                      type='checkbox'
                      id={`week-${index}`}
                      name={`week-${index}`}
                      value={week}
                      checked={selectedWeeks.includes(week)}
                      onChange={() => handleWeekChange(week)}
                      disabled={selectedWeeks.includes('All Weeks')}
                    />
                  </div>
                ))}
                {error && <p className='error-message'>{error}</p>}
              </div>
  
              <div className='form-group'>
                <label htmlFor='emergencyContact'>Emergency Contact Name:</label>
                <input
                  type='text'
                  id='emergencyContact'
                  name='emergencyContact'
                  placeholder='Enter emergency contact name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergencyContact}
                  className={formik.touched.emergencyContact && formik.errors.emergencyContact ? 'error' : ''}
                />
                {formik.touched.emergencyContact && formik.errors.emergencyContact ? (
                  <div className='error-message'>{formik.errors.emergencyContact}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='emergencyContactNumber'>Emergency Contact Number:</label>
                <input
                  type='tel'
                  id='emergencyContactNumber'
                  name='emergencyContactNumber'
                  placeholder='Enter emergency contact number'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergencyContactNumber}
                  className={formik.touched.emergencyContactNumber && formik.errors.emergencyContactNumber ? 'error' : ''}
                />
                {formik.touched.emergencyContactNumber && formik.errors.emergencyContactNumber ? (
                  <div className='error-message'>{formik.errors.emergencyContactNumber}</div>
                ) : null}
              </div>
              
              <hr className="form-separator"/>
  
  
              <h1>Waivers</h1>
              <div className='info-box'>
                <h1>WAIVER, CONSENT AND AUTHORIZATION</h1>
                <hr className='form-separator' />
                <p className='info-text'>In consideration of the Ottawa Lions Track & Field Club (The "Club") acceptoing my child's 
                application as a participant in the above said program, I aftree that my child will abide by the rules and 
                regulations, policies and procedures of the Club in respect to the said program. I am aware of the possibility of 
                health and safety risks associated with my child's participation in the activities and I freely accept all risks 
                associated with his/her participation. I assume all risks incidental to such participation, and do waive, release, 
                absolve, indemnify and agree to hold garmless, other than for willful defualt or negligence on their partm the 
                Club, its officers, directors, employees or agents, will notify the Club of my child's special medical condition 
                or health history, if any. If the emergency contact person identified in this form cannot be reached and my child
                 has an enjury, accident or falls ill, I hereby authorize the Club to provide my child with or make arrangements 
                 for emergency medical treatment.</p>
              </div>
              {/* Checkbox for the first waiver */}
              <div className='checkbox-container'>
                <label htmlFor='waiver1'>I have read and agree to the above *WAIVER, CONSENT AND AUTHORIZATION</label>
                <input 
                  type='checkbox'
                  onChange={handleCheckbox1Change}
                  checked={isWaiver1Checked}
                  id='waiver1' />
              </div>
              {!isWaiver1Checked && <p className='error-message'>Please agree to the waiver.</p>}
              {/* Info box */}
              <div className='info-box'>
                <h2>Ottawa Lions Photo Release Form</h2>
                <hr className='form-separator' />
                <p className='info-text'>I hereby grant the Ottawa Lions Track & Field Club Inc. permission to use my likeness in a
                 photograph, video, or other digital media ("photo") in any and all of its publications, including web-based 
                 publications, without payment or other consideration.</p>
              </div>
              {/* Checkbox for the second waiver */}
              <div className='checkbox-container'>
                <label htmlFor='waiver2'>I have read and agree to the above *Photo Release Form</label>
                <input 
                  type='checkbox'
                  onChange={handleCheckbox2Change}
                  checked={isWaiver2Checked} 
                  id='waiver2' />
              </div>
              {!isWaiver2Checked && <p className='error-message'>Please agree to the waiver.</p>}
  
              {/* Digital signature input */}
              <div className='form-group'>
                <label htmlFor='digitalSignature'>Digital signature (type your name):</label>
                <input
                  type='text'
                  id='digitalSignature'
                  name='digitalSignature'
                  placeholder='Enter your name'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.digitalSignature}
                  className={formik.touched.digitalSignature && formik.errors.digitalSignature ? 'error' : ''}
                />
                {formik.touched.digitalSignature && formik.errors.digitalSignature ? (
                  <div className='error-message'>{formik.errors.digitalSignature}</div>
                ) : null}
              </div>
  
              <hr className="form-separator"/>
  
  
              <h2>Payment Method</h2>
              <div className='gender-section'>
                <label>
                  Credit Card
                  <input
                    type='radio'
                    name='paymentmethod'
                    value='creditcard'
                    checked={payment === 'creditcard'}
                    onChange={handlePaymentChange}
                  />
                </label>
                <label>
                  e-transfer
                  <input
                    type='radio'
                    name='paymentmethod'
                    value='etransfer'
                    checked={payment === 'etransfer'}
                    onChange={handlePaymentChange}
                  />
                </label>
              </div>
              {!isPaymentSelected && <p className='error-message'>Please select a payment method.</p>}
  
  
              <button 
                type='submit' 
                className='enroll-button'
                disabled={!formik.isValid || !isWaiver1Checked || !isWaiver2Checked || !isPaymentSelected}>
                  ENROLL NOW
              </button>
              
            </form>
          </div>
        
      </div>
  );
  
  const frenchContent = (
    <div className='outer-container-child'>
        <div className='container'>
          <div className='info'>
            <h1>Inscription au Camp d'Été</h1>
            <p className='description'>
              Bienvenue dans notre excitant camp d'été ! Laissez votre enfant explorer la grande nature,
              se faire de nouveaux amis et créer des souvenirs inoubliables. Les places sont limitées, alors
              inscrivez votre enfant dès aujourd'hui !
            </p>
            <div className='camp-details'>
              <p><strong>Tranche d'âge :</strong> 10 - 17 ans</p>
              <p><strong>Heure de dépôt :</strong> 8h30</p>
              <p><strong>Heure de ramassage :</strong> 16h30</p>
              <p><strong>Lieu du camp :</strong> Camping Sunny Valley</p>
              <p><strong>Période :</strong> 8 juillet - 16 août</p>
            </div>
            <div className='pricing'>
              <h2>Tarifs</h2>
              <p><strong>Vous pouvez inscrire votre enfant pour notre camp d'été, en sélectionnant n'importe quelle semaine</strong></p>
              <p>Prix régulier : 199 $ par semaine</p>
              <p>Forfait complet du camp : 499 $ pour 6 semaines</p>
            </div>
          </div>
            
          <div className="info-box">
              <p className="info-text">Le paiement des frais est dû lors de l'inscription par carte de crédit.
  
              Contactez le bureau une fois l'inscription terminée et vous recevrez les frais appropriés à payer.
              Le paiement peut ensuite être effectué par virement à <strong>upandproudcommunity@gmail.com</strong> (le message de virement DOIT inclure
              le nom de l'enfant et le programme d'âge dans lequel il est inscrit)
  
              Le paiement peut également être effectué par chèque ou en espèces, qui peuvent être acceptés par le registraire le premier jour de pratique
  
              REMBOURSEMENTS COMPLETS ACCORDÉS DANS LES 3 JOURS SUIVANT L'INSCRIPTION. TOUS LES REMBOURSEMENTS AU-DELÀ DE 3 JOURS SERONT SOUMIS À DES FRAIS ADMINISTRATIFS DE 50 $</p>
            </div>
  
            <form className='registration-form' onSubmit={formik.handleSubmit}>
            <h2>Inscrire des Enfants</h2>
              <div className='form-group'>
                <label htmlFor='childName'>Nom de l'Enfant :</label>
                <input
                  type='text'
                  id='childName'
                  name='childName'
                  placeholder="Entrez le nom de l'enfant"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.childName}
                  className={formik.touched.childName && formik.errors.childName ? 'error' : ''}
                />
                {formik.touched.childName && formik.errors.childName ? (
                  <div className='error-message'>{formik.errors.childName}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='age'>Âge :</label>
                <input
                  type='number'
                  id='age'
                  name='age'
                  placeholder="Entrez l'âge de l'enfant"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.age}
                  className={formik.touched.age && formik.errors.age ? 'error' : ''}
                />
                {formik.touched.age && formik.errors.age ? (
                  <div className='error-message'>{formik.errors.age}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label>Genre :</label>
                <div className='gender-section'>
                  <label>
                    Garçon
                    <input
                      type='radio'
                      name='gender'
                      value='male'
                      checked={formik.values.gender === 'male'}
                      onChange={formik.handleChange}
                    />
                  </label>
                  <label>
                    Fille
                    <input
                      type='radio'
                      name='gender'
                      value='female'
                      checked={formik.values.gender === 'female'}
                      onChange={formik.handleChange}
                    />
                  </label>
                </div>
  
                {formik.touched.gender && formik.errors.gender ? (
                  <div className='error-message'>{formik.errors.gender}</div>
                ) : null}
              </div>
              
              <div className='form-group'>
                <label htmlFor='parentName'>Nom du Parent :</label>
                <input
                  type='text'
                  id='parentName'
                  name='parentName'
                  placeholder="Entrez le nom du parent"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.parentName}
                  className={formik.touched.parentName && formik.errors.parentName ? 'error' : ''}
                />
                
                {formik.touched.parentName && formik.errors.parentName ? (
                  <div className='error-message'>{formik.errors.parentName}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='email'>Email :</label>
                <input
                  type='email'
                  id='email'
                  name='email'
                  placeholder="Entrez l'email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={formik.touched.email && formik.errors.email ? 'error' : ''}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div className='error-message'>{formik.errors.email}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='phone'>Numéro de Téléphone :</label>
                <input
                  type='tel'
                  id='phone'
                  name='phone'
                  placeholder='Entrez le numéro de téléphone'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  className={formik.touched.phone && formik.errors.phone ? 'error' : ''}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div className='error-message'>{formik.errors.phone}</div>
                ) : null}
              </div>
  
              <label>Sélectionner les Semaines :</label>
              <div className='week-checkboxes'>
                <div className='week-checkbox'>
                  <label htmlFor='allWeeks'>Toutes les Semaines</label>
                  <input
                    type='checkbox'
                    id='allWeeks'
                    name='allWeeks'
                    value='All Weeks'
                    checked={selectedWeeks.includes('All Weeks')}
                    onChange={() => handleWeekChange('All Weeks')}
                  />
                </div>
                {weeks.map((week, index) => (
                  <div key={index} className='week-checkbox'>
                    <label htmlFor={`week-${index}`}>{week}</label>
                    <input
                      type='checkbox'
                      id={`week-${index}`}
                      name={`week-${index}`}
                      value={week}
                      checked={selectedWeeks.includes(week)}
                      onChange={() => handleWeekChange(week)}
                      disabled={selectedWeeks.includes('All Weeks')}
                    />
                  </div>
                ))}
                {error && <p className='error-message'>{error}</p>}
              </div>
  
              <div className='form-group'>
                <label htmlFor='emergencyContact'>Nom du Contact d'Urgence :</label>
                <input
                  type='text'
                  id='emergencyContact'
                  name='emergencyContact'
                  placeholder="Entrez le nom du contact d'urgence"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergencyContact}
                  className={formik.touched.emergencyContact && formik.errors.emergencyContact ? 'error' : ''}
                />
                {formik.touched.emergencyContact && formik.errors.emergencyContact ? (
                  <div className='error-message'>{formik.errors.emergencyContact}</div>
                ) : null}
              </div>
  
              <div className='form-group'>
                <label htmlFor='emergencyContactNumber'>Numéro du Contact d'Urgence :</label>
                <input
                  type='tel'
                  id='emergencyContactNumber'
                  name='emergencyContactNumber'
                  placeholder="Entrez le numéro du contact d'urgence"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emergencyContactNumber}
                  className={formik.touched.emergencyContactNumber && formik.errors.emergencyContactNumber ? 'error' : ''}
                />
                {formik.touched.emergencyContactNumber && formik.errors.emergencyContactNumber ? (
                  <div className='error-message'>{formik.errors.emergencyContactNumber}</div>
                ) : null}
              </div>
              
              <hr className="form-separator"/>
  
  <h1>Dérogations</h1>
  <div className='info-box'>
    <h1>DÉROGATION, CONSENTEMENT ET AUTORISATION</h1>
    <hr className='form-separator' />
    <p className='info-text'>En considération de l'acceptation par le Ottawa Lions Track & Field Club (le "Club") de la demande de mon enfant pour participer au programme susmentionné, je conviens que mon enfant respectera les règles et règlements, les politiques et les procédures du Club concernant ledit programme. Je suis conscient des risques pour la santé et la sécurité associés à la participation de mon enfant aux activités et j'accepte librement tous les risques associés à sa participation. J'assume tous les risques accessoires à une telle participation, et je renonce, libère, absolve, indemnise et m'engage à dégager de toute responsabilité, sauf en cas de faute intentionnelle ou de négligence de leur part, le Club, ses dirigeants, administrateurs, employés ou agents. Je m'engage à informer le Club de toute condition médicale spéciale ou de tout antécédent médical de mon enfant, le cas échéant. Si la personne de contact d'urgence identifiée dans ce formulaire ne peut être jointe et que mon enfant a une blessure, un accident ou tombe malade, j'autorise par la présente le Club à fournir à mon enfant ou à prendre des dispositions pour un traitement médical d'urgence.</p>
  </div>
  {/* Case à cocher pour la première dérogation */}
  <div className='checkbox-container'>
    <label htmlFor='waiver1'>J'ai lu et j'accepte la *DÉROGATION, CONSENTEMENT ET AUTORISATION ci-dessus</label>
    <input 
      type='checkbox'
      onChange={handleCheckbox1Change}
      checked={isWaiver1Checked}
      id='waiver1' />
  </div>
  {!isWaiver1Checked && <p className='error-message'>Veuillez accepter la dérogation.</p>}
  {/* Info box */}
  <div className='info-box'>
    <h2>Formulaire d'Autorisation de Photo d'Ottawa Lions</h2>
    <hr className='form-separator' />
    <p className='info-text'>Je donne par la présente au Ottawa Lions Track & Field Club Inc. la permission d'utiliser mon image dans une photographie, une vidéo ou un autre média numérique ("photo") dans toutes ses publications, y compris les publications en ligne, sans paiement ni autre considération.</p>
  </div>
  {/* Case à cocher pour la deuxième dérogation */}
  <div className='checkbox-container'>
    <label htmlFor='waiver2'>J'ai lu et j'accepte le *Formulaire d'Autorisation de Photo ci-dessus</label>
    <input 
      type='checkbox'
      onChange={handleCheckbox2Change}
      checked={isWaiver2Checked} 
      id='waiver2' />
  </div>
  {!isWaiver2Checked && <p className='error-message'>Veuillez accepter la dérogation.</p>}
  
  {/* Champ de signature numérique */}
  <div className='form-group'>
    <label htmlFor='digitalSignature'>Signature numérique (tapez votre nom) :</label>
    <input
      type='text'
      id='digitalSignature'
      name='digitalSignature'
      placeholder='Entrez votre nom'
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      value={formik.values.digitalSignature}
      className={formik.touched.digitalSignature && formik.errors.digitalSignature ? 'error' : ''}
    />
    {formik.touched.digitalSignature && formik.errors.digitalSignature ? (
      <div className='error-message'>{formik.errors.digitalSignature}</div>
    ) : null}
  </div>
  
  <hr className="form-separator"/>
  
  <h2>Méthode de Paiement</h2>
  <div className='gender-section'>
    <label>
      Carte de Crédit
      <input
        type='radio'
        name='paymentmethod'
        value='creditcard'
        checked={payment === 'creditcard'}
        onChange={handlePaymentChange}
      />
    </label>
    <label>
      Virement électronique
      <input
        type='radio'
        name='paymentmethod'
        value='etransfer'
        checked={payment === 'etransfer'}
        onChange={handlePaymentChange}
      />
    </label>
  </div>
  {!isPaymentSelected && <p className='error-message'>Veuillez sélectionner une méthode de paiement.</p>}
  
  <button 
    type='submit' 
    className='enroll-button'
    disabled={!formik.isValid || !isWaiver1Checked || !isWaiver2Checked || !isPaymentSelected}>
      INSCRIVEZ-VOUS MAINTENANT
  </button>
            </form>
          </div>
      </div>
  )

  return selectedLanguage === "French" ? frenchContent : englishContent;
};
export default ChildRegistration;
