import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import './Home.css';
import Image from "../../images/HeroImage.png";
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const Home = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const englishContent = (
    <div className="home-container">
      {/* Call to Action Section */}
      <div className="call-to-action">
        <h4>we believe in . . .</h4>
        <h1>Making a difference through collective action</h1>
        <Link to="/about">
          <button className="learn-more-button">Learn More About Us</button>
        </Link>
        <img src={Image} alt="Hero"/>
      </div>

      {/* Resources Section */}
      <div className="resources-section">
        <h1>Explore Our Resources</h1>
        <h4>Explore the diverse range of resources we offer to support your journey in innovation and professional growth</h4>

        <div className="resource-boxes-container">
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Publications</h2>
            </div>
            <p>cutting-edge research and thought-provoking publications curated by experts in the field</p>
          </div>
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Podcast</h2>
            </div>
            <p>where industry experts, thought leaders, and innovators share their stories and expertise</p>
          </div>
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>News</h2>
            </div>
            <p>latest noteworthy achievements, milestones, and relevant industry developments</p>
          </div>
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Events</h2>
            </div>
            <p>previous highlights and diverse range of upcoming events to foster collaboration and community building</p>
          </div>
        </div>

        {/* Button Container */}
        <div className="button-container">
          <Link to="/contact" className="section-button">
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );

  const frenchContent = (
    <div className="home-container">
      {/* Call to Action Section */}
      <div className="call-to-action">
        <h4>nous croyons en . . .</h4>
        <h1>Faire une différence par l'action collective</h1>
        <Link to="/about">
          <button className="learn-more-button">En savoir plus sur nous</button>
        </Link>
        <img src={Image} alt="Hero"/>
      </div>

      {/* Resources Section */}
      <div className="resources-section">
        <h1>Découvrez nos ressources</h1>
        <h4>Découvrez la gamme diversifiée de ressources que nous offrons pour soutenir votre parcours d'innovation et de croissance professionnelle</h4>

        <div className="resource-boxes-container">
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Publications</h2>
            </div>
            <p>recherche de pointe et publications stimulantes sélectionnées par des experts du domaine</p>
          </div>
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Podcast</h2>
            </div>
            <p>où des experts de l'industrie, des leaders d'opinion et des innovateurs partagent leurs histoires et leur expertise</p>
          </div>
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Nouvelles</h2>
            </div>
            <p>derniers succès, étapes importantes et développements pertinents de l'industrie</p>
          </div>
          <div className="resource-box">
            <div className="resource-box-title">
              <h2>Événements</h2>
            </div>
            <p>faits saillants précédents et vaste gamme d'événements à venir pour favoriser la collaboration et la création de communauté</p>
          </div>
        </div>

        {/* Button Container */}
        <div className="button-container">
          <Link to="/contact" className="section-button">
            Contactez-nous
          </Link>
        </div>
      </div>
    </div>
  );

  return selectedLanguage === "French" ? frenchContent : englishContent;
};

export default Home;
