import React, { useContext } from 'react';
import './Resources.css';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const content = {
  English: {
    title: 'Resources',
    sections: [
      {
        subtitle: 'Summer Camps',
        links: [
          { name: 'Ottawa Summer Camps', url: 'https://www.ottawasummercamps.ca/' },
          { name: 'Ottawa Parenting Times - Summer Camps Guide', url: 'https://www.ottawaparentingtimes.ca/summer-camps-guide/' },
        ],
      },
      {
        subtitle: 'Coding Resources',
        links: [
          { name: 'Code.org', url: 'https://code.org/' },
          { name: 'Khan Academy - Computing', url: 'https://www.khanacademy.org/computing' },
          { name: 'Scratch - Imagine, Program, Share', url: 'https://scratch.mit.edu/' },
        ],
      },
      {
        subtitle: 'Basketball',
        links: [
          { name: 'Canada Basketball', url: 'https://www.basketball.ca/' },
          { name: 'Ontario Basketball', url: 'https://www.basketball.on.ca/' },
          { name: 'NBA Canada', url: 'https://www.nba.com/canada/' },
        ],
      },
      {
        subtitle: 'Other Helpful Resources',
        links: [
          { name: 'City of Ottawa', url: 'https://www.ottawa.ca/en' },
          { name: 'Ottawa Public Health', url: 'https://www.ottawapublichealth.ca/en/index.aspx' },
        ],
      },
    ],
  },
  French: {
    title: 'Ressources',
    sections: [
      {
        subtitle: 'Camps d\'été',
        links: [
          { name: 'Camps d\'été à Ottawa', url: 'https://www.ottawasummercamps.ca/' },
          { name: 'Guide des camps d\'été - Ottawa Parenting Times', url: 'https://www.ottawaparentingtimes.ca/summer-camps-guide/' },
        ],
      },
      {
        subtitle: 'Ressources en Programmation',
        links: [
          { name: 'Code.org', url: 'https://code.org/' },
          { name: 'Khan Academy - Informatique', url: 'https://www.khanacademy.org/computing' },
          { name: 'Scratch - Imaginez, Programmez, Partagez', url: 'https://scratch.mit.edu/' },
        ],
      },
      {
        subtitle: 'Basketball',
        links: [
          { name: 'Basketball Canada', url: 'https://www.basketball.ca/' },
          { name: 'Basketball Ontario', url: 'https://www.basketball.on.ca/' },
          { name: 'NBA Canada', url: 'https://www.nba.com/canada/' },
        ],
      },
      {
        subtitle: 'Autres Ressources Utiles',
        links: [
          { name: 'Ville d\'Ottawa', url: 'https://www.ottawa.ca/en' },
          { name: 'Santé publique d\'Ottawa', url: 'https://www.ottawapublichealth.ca/en/index.aspx' },
        ],
      },
    ],
  },
};

const Resources = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const { title, sections } = content[selectedLanguage] || content.English;

  return (
    <div className='resources-outer-container'>
      <div className='resources-container'>
        <h1 className='resources-title'>{title}</h1>
        {sections.map((section, index) => (
          <div key={index} className='resources-page-section'>
            <h2 className='resources-subtitle'>{section.subtitle}</h2>
            <ul className='resources-list'>
              {section.links.map((link, index2) => (
                <li key={index2} className='resources-item'>
                  <a href={link.url} target='_blank' rel='noopener noreferrer'>{link.name}</a>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Resources;
