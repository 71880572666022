import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { FaUser } from 'react-icons/fa';
import Image from "../../images/Logo.png";
import LanguagePicker from '../languagepicker/LanguagePicker';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';
import { UserContext } from '../../contexts/UserContext/UserContext'
import { signOutUser } from '../../firebase/firebaseAuth';
import Form from '../form/Form';
import SignInForm from '../form/SignInForm';

export const Header = () => {
  const { selectedLanguage } = useContext(LanguageContext);
  const { user, setUser } = useContext(UserContext);
  const navigate = useNavigate();
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showSignInModal, setShowSignInModal] = useState(false);

  const openRegisterModal = () => {
    setShowRegisterModal(true);
  };

  const closeRegisterModal = () => {
    setShowRegisterModal(false);
  };

  const openSignInModal = () => {
    setShowSignInModal(true);
  };

  const closeSignInModal = () => {
    setShowSignInModal(false);
  };

  useEffect(() => {
    checkLocalStorageUser();
  }, []);

  const checkLocalStorageUser = () => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  };

  const handleLogout = async () => {
    try {
      await signOutUser();
      setUser(null); // Update the user context to clear the user
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const handleSignIn = async () => {
    if (user) {
      // User is logged in, redirect to child registration page
      navigate('/child-registration');
    } else {
      // User is not logged in, open sign in modal
      openSignInModal();
    }
  }

  const englishContent = (
    <div className="header-container">
      <img src={Image} alt="Community Impact Hub" style={{ width: '200px', height: 'auto%' }} />
      <nav>
        <ul className="header-tabs">
          <li><Link to="/" className="header-link">Home</Link></li>
          <li><Link to="/about" className="header-link">About Us</Link></li>
          <li><Link to="/resources" className="header-link">Resources</Link></li>
          <li><Link to="/programs" className="header-link">Programs & Services</Link></li>
          <li><Link to="/contact" className="header-link">Contact Us</Link></li>
          <li><Link to="/summercamp" className="header-link">Summer Camp Program</Link></li>
        </ul>
      </nav>

      <nav>
        <ul>
          {user ? <>
            <li className='user-email'>{user.email}</li>
            <li><button onClick={handleLogout} className="header-button">Logout</button></li>
          </> : (
            <li><button onClick={handleSignIn} className="header-button">Sign In</button></li>
          )}
          <li><LanguagePicker /></li>
        </ul>
      </nav>
      {showRegisterModal && <Form onClose={closeRegisterModal} onBack={openSignInModal} />}
      {showSignInModal && <SignInForm onClose={closeSignInModal} onSignUp={openRegisterModal} />}
    </div>
  );

  const frenchContent = (
    <div className="header-container">
      <img src={Image} alt="Community Impact Hub" style={{ width: '200px', height: 'auto%' }} />
      <nav>
        <ul className="header-tabs">
          <li><Link to="/" className="header-link">Accueil</Link></li>
          <li><Link to="/about" className="header-link">À propos de nous</Link></li>
          <li><Link to="/resources" className="header-link">Ressources</Link></li>
          <li><Link to="/programs" className="header-link">Programmes et services</Link></li>
          <li><Link to="/contact" className="header-link">Nous contacter</Link></li>
          <li><Link to="/summercamp" className="header-link">Programme de camp d'été</Link></li>
        </ul>
      </nav>

      <nav>
        <ul>
          {user ? <>
            <li className='user-email'>{user.email}</li>
            <li><button onClick={handleLogout} className="header-button">Se déconnecter</button></li>
          </> : (
            <li><button onClick={handleSignIn} className="header-button">Se connecter</button></li>
          )}
          <li><LanguagePicker /></li>
        </ul>
      </nav>
      {showRegisterModal && <Form onClose={closeRegisterModal} onBack={openSignInModal} />}
      {showSignInModal && <SignInForm onClose={closeSignInModal} onSignUp={openRegisterModal} />}
    </div>
  );

  return selectedLanguage === "French" ? frenchContent : englishContent;
};

export default Header;
