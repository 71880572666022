import React, { useContext } from 'react';
import './PageNotFound.css';
import LanguageContext from '../../contexts/LanguageContext/LanguageContext';

const content = {
  English: {
    title: '404 Page Not Found',
    message: 'This URL is not found. Please go back.',
  },
  French: {
    title: '404 Page Non Trouvée',
    message: 'Cette URL est introuvable. Veuillez revenir en arrière.',
  },
};

export const PageNotFound = () => {
  const { selectedLanguage } = useContext(LanguageContext);

  const { title, message } = content[selectedLanguage] || content.English;

  return (
    <div className="not-found-container">
      <div className="not-found-content">
        <h1 className='errorh1'>{title}</h1>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default PageNotFound;
